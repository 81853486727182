<template>
  <b-card>
    <div>
      <b-row>
        <div v-if="!showFilters">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showFilters = true"
            title="Filtros"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="SlidersIcon" />
          </b-button>
        </div>
        <div v-else>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showFilters = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="ChevronUpIcon" />
          </b-button>
        </div>

        <div v-if="!showSend">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showSend = true"
            title="Envios"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="SendIcon" />
          </b-button>
        </div>
        <div v-else>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showSend = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>

        <div v-if="!showSearch">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showSearch = true"
            title="Pesquisar"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </div>
        <div v-else>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showSearch = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
        <b-col v-show="showSearch">
          <b-form-input
            placeholder="Pesquisar"
            v-model="search"
            debounce="800"
            type="search"
          />
        </b-col>
      </b-row>

      <div v-show="showFilters">
        <h4>Filtros</h4>
        <b-row>
          <b-col md="6" xl="2" class="p-1">
            <label> Tipo de contrato </label>
            <b-form-select v-model="typeContract" :options="typeContractOptions" />
          </b-col>
          <b-col md="6" xl="2" class="p-1">
            <label> Situação contrato </label>
            <b-form-select v-model="contractSituation" :options="contractSituationOptions" />
          </b-col>
          <b-col md="6" xl="2" class="p-1">
            <label> Situação titulo </label>
            <b-form-select v-model="titleSituation" :options="titleSituationOptions" />
          </b-col>
          <b-col md="6" xl="2" class="p-1">
            <label> Débito automático </label>
            <b-form-select v-model="automaticDebit" :options="automaticDebitOptions" />
          </b-col>
          <b-col md="6" xl="2" class="p-1">
            <label> Fatura digital </label>
            <b-form-select v-model="onlineInvocie" :options="onlineInvocieOptions" />
          </b-col>
          <b-col md="6" xl="2" class="p-1">
            <label> Dias em atraso </label>
            <b-form-select v-model="selected" :options="options" />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="1" class="p-1">
            <label> Qtd. dias </label>
            <b-form-input type="text" />
          </b-col>
          <b-col md="6" xl="2" class="p-1">
            <label> Venc. Inicial </label>
            <b-form-datepicker
              id="datepicker-dateformat1"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
              }"
              v-model="date_ini"
              placeholder="Início"
              local="br"
            />
          </b-col>
          <b-col md="6" xl="2" class="p-1">
            <label> Venc. Final </label>
            <b-form-datepicker
              id="datepicker-dateformat2"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
              }"
              v-model="date_end"
              placeholder="Fim"
              local="br"
            />
          </b-col>
          <b-col md="6" xl="2" class="p-1">
            <label> Dias de cancelamento </label>
            <b-form-select v-model="selected" :options="options" />
          </b-col>
          <b-col md="6" xl="1" class="p-1">
            <label> Qtd. dias </label>
            <b-form-input type="text" />
          </b-col>
          <b-col md="6" xl="2" class="p-1">
            <label> Cancelamento Inicial </label>
            <b-form-datepicker
              id="datepicker-dateformat1"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
              }"
              v-model="canc_ini"
              placeholder="Início"
              local="br"
            />
          </b-col>
          <b-col md="6" xl="2" class="p-1">
            <label> Cancelamento Final </label>
            <b-form-datepicker
              id="datepicker-dateformat2"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
              }"
              v-model="canc_ini"
              placeholder="Fim"
              local="br"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="2" class="p-1">
            <label> Possui telefone? </label>
            <b-form-select v-model="hasPhone" :options="hasPhoneOptions" />
          </b-col>
          <b-col md="6" xl="2" class="p-1">
            <label> Possui email? </label>
            <b-form-select v-model="hasMail" :options="hasMailOptions" />
          </b-col>
          <b-col md="6" xl="2" class="p-1">
            <label> Carteira(s) </label>
            <b-form-select v-model="selected" :options="options" />
          </b-col>
        </b-row>
      </div>

      <div v-show="showSend">
        <hr />
        <h4>Opções de envio manual</h4>
        <b-row>
          <b-col md="6" xl="3" class="p-1">
            <label> Selecione uma mensagem </label>
            <b-form-select v-model="selected" :options="options" />
          </b-col>
          <b-col md="6" xl="3" class="p-1">
            <label> Selecione os canais de envio </label>
            <b-form-select v-model="selected" :options="options" />
          </b-col>
          <b-col md="6" xl="3" class="p-1 mt-2">
            <b-button variant="relief-primary" block>
              <feather-icon icon="PlayIcon" />
              Iniciar ação
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
        <b-table-simple
          striped
          stacked="md"
          noDataText="Você ainda não possui clientes cadastrados."
          class="mt-2 responsive"
          id="tableTitles"
          :item="fetchTitles"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <b-thead class="text-center">
            <b-th> Código </b-th>
            <b-th> Cliente </b-th>
            <b-th> Título </b-th>
            <b-th> Valor </b-th>
            <b-th> Vencimento </b-th>
            <b-th> Dias em atraso </b-th>
            <b-th> Referência </b-th>
            <b-th> Cód. Barras </b-th>
            <b-th> Ações </b-th>
          </b-thead>
          <b-tbody>
            <b-tr :key="indextr" v-for="(tr, indextr) in titles">
              <b-td> {{ tr.id }} </b-td>
              <b-td> {{ tr.client.name }} </b-td>
              <b-td> {{ tr.title_number }} </b-td>
              <b-td> {{ tr.value | moneyFormat }} </b-td>
              <b-td> {{ tr.expiry | dateFormat }} </b-td>
              <b-td class="text-center" v-if="tr.charged_today != '0'"> {{ tr.charged_today }} </b-td>
              <b-td class="text-center" v-else> Em dia </b-td>
              <b-td v-if="tr.reference != null" class="text-center">
                {{ tr.reference }}
              </b-td>
              <b-td class="text-center">
                -
              </b-td>
              <b-td class="text-center"> 
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                >
                  <b-icon icon="upc" font-scale="1.5"></b-icon>
                </b-button>
              </b-td>
              <b-td>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-warning"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                  v-b-modal.modal-delete-tenants
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-overlay>
      <b-pagination
        @change="handlePageChange"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        aria-controls="tableTitles"
        class="mt-1"
      />
    </div>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
import { BButton } from "bootstrap-vue";
// import vSelect from "vue-select";

export default {
  components: {
    BButton,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      isLoading: true,

      showFilters: false,

      showSearch: false,

      showSend: false,

      search: null,

      date_ini: null,
      date_end: null,
      canc_ini: null,
      canc_end: null,

      typeContract: 'T',
      typeContractOptions: [
        { value: 'T', text: "Todos" },
        { value: "f", text: "Pessoa física" },
        { value: "j", text: "Pessoa jurídica" },
      ],

      contractSituation: 'T',
      contractSituationOptions: [
        { value: 'T', text: "Todos" },
        { value: "1", text: "Ativo" },
        { value: "0", text: "Cancelado" },
      ],

      titleSituation: '0',
      titleSituationOptions: [
        { text: "Todos", value: "0" },
        { text: "A vencer", value: "1" },
        { text: "Vencidos", value: "2" }
      ],


      automaticDebit: 'T',
      automaticDebitOptions: [
        { value: 'T', text: "Todos" },
        { value: "S", text: "Sim" },
        { value: "N", text: "Não" },
      ],

      onlineInvocie: 'T',
      onlineInvocieOptions: [
        { value: 'T', text: "Todos" },
        { value: "S", text: "Sim" },
        { value: "N", text: "Não" },
      ],

      hasPhone: 'T',
      hasPhoneOptions: [
        { value: 'T', text: "Todos" },
        { value: "S", text: "Sim" },
        { value: "N", text: "Não" },
      ],

      hasMail: 'T',
      hasMailOptions: [
        { value: 'T', text: "Todos" },
        { value: "S", text: "Sim" },
        { value: "N", text: "Não" },
      ],

      selected: null,
      options: [
        { value: 'T', text: "Todos" },
        { value: "S", text: "Sim" },
        { value: "N", text: "Não" },
      ],
 
    };
  },
  computed: {
    ...mapState("titles", ["titles", "pagination"]),
  },
  methods: {
    fetchTitles(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("titles/fetchTitles", {
          paginated: paginated,
          page: page,
          generic: this.search,
          contract_type: this.typeContract,
          title_situation: this.contractSituation,
          contract_situation: this.titleSituation,
          automatic_debit: this.automaticDebit,
          online_invoice: this.onlineInvocie,
          date_ini: this.date_ini,
          date_end: this.date_end,
          canc_ini: this.canc_ini,
          canc_end: this.canc_end,
          hasPhone: this.hasPhone,
          hasMail: this.hasMail,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handlePageChange(value) {
      this.fetchTitles(true, value);
    },
  },
  created() {
    this.fetchTitles();
  },
  watch: {
    search(val) {
      this.currentPage = 1;
      this.fetchTitles(true, 1);
    },
    typeContract(val) {
      this.currentPage = 1;
      this.fetchTitles(true, 1);
    },
    contractSituation(val) {
      this.currentPage = 1;
      this.fetchTitles(true, 1);
    },
    titleSituation(val) {
      this.currentPage = 1;
      this.fetchTitles(true, 1);
    },
    automaticDebit(val) {
      this.currentPage = 1;
      this.fetchTitles(true, 1);
    },
    onlineInvocie(val) {
      this.currentPage = 1;
      this.fetchTitles(true, 1);
    },
    date_ini(val) {
      this.currentPage = 1;
      this.fetchTitles(true, 1);
    },
    date_end(val) {
      this.currentPage = 1;
      this.fetchTitles(true, 1);
    },
    canc_ini(val) {
      this.currentPage = 1;
      this.fetchTitles(true, 1);
    },
    canc_end(val) {
      this.currentPage = 1;
      this.fetchTitles(true, 1);
    },
    hasPhone(val) {
      this.currentPage = 1;
      this.fetchTitles(true, 1);
    },
    hasMail(val) {
      this.currentPage = 1;
      this.fetchTitles(true, 1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
